<template>
  <PageWrapper>
    <PagePanel>
      <PageSearchArea buttonTestId="UPDATE" @queryData="setStartAndEndDate">
        <template #form>
          <el-form label-position="top">
            <AccountNumber ref="accountNumber" @accountCallback="init"></AccountNumber>
            <dateRangePicker v-bind:start-date.sync="startDate" v-bind:end-date.sync="endDate"></dateRangePicker>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>

    <RebateCard ref="rebate" class="history_rebate" :userID="userID" @accountIdAndCurrency="getAccountIdAndCurrency"> </RebateCard>

    <PagePanel :tabs="selectedCardList" :tabActive.sync="selectedCard">
      <div>
        <div v-show="selectedCard == 'rebateHistory'">
          <el-table :data="rebateHistoryData.rows" style="width: 100%;">
            <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="120"> </el-table-column>
            <el-table-column prop="rangeDate" :label="$t('common.keys.DATERANGE')" min-width="120">
            </el-table-column>
            <el-table-column prop="amount" :label="$t('common.keys.amount').toUpperCase()" min-width="120">
              <template slot-scope="rebateTable">
                {{ currAccountCurrency | currencySymbol }}{{ rebateTable.row.amount | currency('') }}
              </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('common.keys.STATUS')" min-width="120">
              <template slot-scope="scope">
                <div :class="scope.row.status === 4 ? 'red' : 'gray'">
                  {{ $t(rebateStatusDesc(scope.row.status)) }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <SPagination :total="rebateHistoryData.total" @current-change="handleRebateCurrentChange"
            @size-change="handleRebateSizeChange" :current-page.sync="rebatePageNo"></SPagination>
        </div>
        <div v-show="selectedCard == 'withdrawHistory'">
          <el-table :data="withdrawHistoryData.rows" style="width: 100%;">
            <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="120"> </el-table-column>
            <el-table-column prop="amount" :label="$t('common.keys.amount').toUpperCase()" min-width="120">
              <template slot-scope="withdrawTable">
                {{ currAccountCurrency | currencySymbol }}{{ withdrawTable.row.amount | currency('') }}
              </template>
            </el-table-column>
            <el-table-column prop="destination" :label="$t('common.keys.DEST')" min-width="150">
              <template slot-scope="scope">
                {{ $t(withdrawTypeDesc(scope.row.destination)) }}
              </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('common.keys.STATUS')" min-width="120">
              <template slot-scope="scope">
                <div :class="scope.row.status === 4 || scope.row.status === 13 ? 'red' : 'gray'">
                  {{ $t(getWithdrawStatus(scope.row.status, scope.row.processedByOp)) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="showCancelWithdraw" min-width="120">
              <template slot-scope="scope">
                <div>
                  <el-button v-if="showCancelButton(scope.row)" @click="cancelWithdraw(scope.row)"
                    class="btn-blue cancel-btn">
                    {{ $t('common.button.cancel') }}
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <SPagination :total="withdrawHistoryData.total" @current-change="handleWithdrawCurrentChange"
            @size-change="handleWithdrawSizeChange" :current-page.sync="withdrawPageNo"></SPagination>
        </div>
        <div v-show="selectedCard == 'transferHistory'">
          <el-table :data="transferHistoryData.rows" style="width: 100%;">
            <el-table-column :label="$t('common.keys.DATE')">
              <template slot-scope="scope">{{ scope.row.date | date }}</template>
            </el-table-column>
            <el-table-column :label="$t('common.keys.FROM')">
              <template slot-scope="scope">{{
                scope.row.fromAccount + ' (' + scope.row.from_currency + ')'
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('common.keys.TO')">
              <template slot-scope="scope">{{
                scope.row.toAccount + ' (' + scope.row.to_currency + ')'
              }}</template>
            </el-table-column>
            <el-table-column prop="amount" :label="$t('common.keys.amount').toUpperCase()">
              <template slot-scope="transferTable">
                {{ currAccountCurrency | currencySymbol }}{{ transferTable.row.amount | currency('') }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.keys.STATUS')">
              <template slot-scope="scope">{{ scope.row.status | payStatus }}</template>
            </el-table-column>
          </el-table>
          <SPagination :total="transferHistoryData.total" @current-change="handleTransferCurrentChange"
            @size-change="handleTransferSizeChange" :current-page.sync="transferPageNo"></SPagination>
        </div>
      </div>
    </PagePanel>

    <!-- cancel withdrawl dialog -->
    <SDialog  :visible.sync="dialogVisible" @cancel="closeDialog" @confirm="submitCancelWithdraw" :cancelButtonText=" $t('common.field.no')" :confirmButtonText="$t('common.field.yes')"> 
      {{ $t('rebatePaymentHistory.cancelWithdraw') }}
    </SDialog>
  </PageWrapper>
</template>

<script>
import RebateCard from '@/views/home/components/RebateCard';
import AccountNumber from '@/components/customUI/AccountNumber';
import DateRangePicker from '@/components/customUI/DateRangePicker';
import { apiRebate, apiCancelWithdraw } from '@/resource';

export default {
  components: { RebateCard, AccountNumber, DateRangePicker },
  data() {
    return {
      userID: this.$store.state.common.CUID,
      startDate: '',
      endDate: '',
      startDateDisplay: '',
      endDateDisplay: '',
      accountID: '',
      currAccountCurrency: '',
      rebatePageNo: 1,
      rebatePageSize: 25,
      withdrawPageNo: 1,
      withdrawPageSize: 25,
      transferPageNo: 1,
      transferPageSize: 25,
      rebateHistoryData: { rows: [], total: 0 },
      withdrawHistoryData: {
        rows: [],
        total: 0
      },
      transferHistoryData: {
        rows: [],
        total: 0
      },
      cancelInfo: null,
      dialogVisible: false,
      ngaCountryCode: 5796,
      selectedCard: 'rebateHistory',
      selectedCardList: [
        { name: 'rebateHistory', dataTestId: 'rebateHistory', label: this.$t('rebatePaymentHistory.rebateHistory') },
        { name: 'withdrawHistory', dataTestId: 'withdrawHistory', label: this.$t('rebatePaymentHistory.withdrawHistory') },
        { name: 'transferHistory', dataTestId: 'transferHistory', label: this.$t('rebatePaymentHistory.transferHistory') },
      ]
    };
  },
  methods: {
    init() {
      this.$refs.rebate.init();
    },
    setStartAndEndDate() {
      this.startDateDisplay = this.startDate;
      this.endDateDisplay = this.endDate;
      this.$nextTick(() => this.initHistory());
    },
    //分页
    handleRebateCurrentChange(pageNo) {
      this.rebatePageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(0));
    },
    handleRebateSizeChange(size) {
      this.rebatePageSize = size;
      this.$nextTick(() => this.queryRebateHistory(0));
    },
    handleWithdrawCurrentChange(pageNo) {
      this.withdrawPageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(1));
    },
    handleWithdrawSizeChange(size) {
      this.withdrawPageSize = size;
      this.$nextTick(() => this.queryRebateHistory(1));
    },
    handleTransferCurrentChange(pageNo) {
      this.transferPageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(2));
    },
    handleTransferSizeChange(size) {
      this.transferPageSize = size;
      this.$nextTick(() => this.queryRebateHistory(2));
    },
    initHistory() {
      this.queryRebateHistory(0);
      this.queryRebateHistory(1);
      this.queryRebateHistory(2);
    },
    queryRebateHistory(type) {
      if (!this.accountID) {
        return;
      }

      let urls = ['rebateHistory', 'withdrawHistory', 'transferHistory'];
      let pageNo = [this.rebatePageNo, this.withdrawPageNo, this.transferPageNo];
      let pageSize = [this.rebatePageSize, this.withdrawPageSize, this.transferPageSize];
      apiRebate(urls[type], {
        pageNo: pageNo[type],
        limit: pageSize[type],
        qUserId: this.userID,
        qAccount: this.accountID,
        qStartTime: this.startDate,
        qEndTime: this.endDate
      }).then(resp => {
        if (resp.data.code === 0) {
          if (type === 0) {
            this.rebateHistoryData.rows = resp.data.data;
            this.rebateHistoryData.total = resp.data.totalRecords;
          } else if (type === 1) {
            this.withdrawHistoryData.rows = resp.data.data;
            this.withdrawHistoryData.total = resp.data.totalRecords;
          } else {
            this.transferHistoryData.rows = resp.data.data;
            this.transferHistoryData.total = resp.data.totalRecords;
          }
        }
      }, this.$handleError);
    },
    getAccountIdAndCurrency(accountID, currency) {
      this.accountID = accountID;
      this.currAccountCurrency = currency;
      this.$nextTick(() => this.initHistory());
    },
    showCancelButton(item) {
      // 20220811 UninoPay not support CancelWithdraw (method: 4)
      const { status, processedByOp, destination } = item;
      return !processedByOp && (status === 1 || status === 3 || status === 5) && destination !== 4;
    },
    cancelWithdraw(info) {
      this.dialogVisible = true;
      this.cancelInfo = info;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.cancelInfo = null;
    },
    submitCancelWithdraw() {
      apiCancelWithdraw({ withdrawalId: this.cancelInfo.id })
        .then(result => {
          if (result.data.code == 0) {
            this.closeDialog();
            this.queryRebateHistory(1);
          } else {
            this.showErrorMsg();
          }
        })
        .catch(() => {
          this.showErrorMsg();
        });
    },
    showErrorMsg() {
      this.$message({
        message: this.$t('rebatePaymentHistory.cancelError', {
          supportEmail: this.GLOBAL_CONTACT_EMAIL
        }),
        type: 'error',
        duration: 10000
      });

      this.closeDialog();
      this.queryRebateHistory(1);
    },
    getWithdrawStatus(status, processedByOp) {
      if (status === 11) {
        return !processedByOp ? this.withdrawStatusDesc(2) : this.withdrawStatusDesc(8);
      }
      if (status === 9) {
        return this.withdrawStatusDesc(3);
      }
      // if status is Processing, change it to submitted
      return !processedByOp && (status === 3 || status === 5)
        ? this.withdrawStatusDesc(1)
        : this.withdrawStatusDesc(status);
    }
  },
  mounted() {
    this.bus.$on('updateRebateHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(0);
      }
    });
    this.bus.$on('updateWithdrawHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(1);
      }
    });
    this.bus.$on('updateTransferHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(2);
      }
    });
    this.startDateDisplay = this.startDate;
    this.endDateDisplay = this.endDate;
  },
  computed: {
    showCancelWithdraw() {
      if (this.withdrawHistoryData.rows === null) {
        return false;
      } else {
        return this.withdrawHistoryData.rows.find(
          // 20220811 UninoPay not support CancelWithdraw (method: 4)
          element =>
            !element.processedByOp &&
            (element.status === 1 || element.status === 3 || element.status === 5) &&
            element.destination !== 4
        );
      }
    },
    rebateStatusDesc() {
      return function (status) {
        const statuses = {
          0: 'rebate.status.processing',
          5: 'rebate.status.processing',
          2: 'rebate.status.processed',
          4: 'rebate.status.rejected'
        };
        return statuses[status];
      };
    },
    withdrawStatusDesc() {
      return function (status) {
        const statuses = {
          1: 'withdraw.status.withdrawalSubmitted',
          2: 'withdraw.status.withdrawalCanceled',
          3: 'withdraw.status.withdrawalAuditing',
          4: 'withdraw.status.withdrawalRejected',
          5: 'withdraw.status.withdrawalAccepted',
          6: 'withdraw.status.withdrawalPayFailed',
          7: 'withdraw.status.withdrawalSuccess',
          8: 'withdraw.status.withdrawalFailed',
          9: 'withdraw.status.withdrawalPending',
          10: 'withdraw.status.withdrawalPartialFailed',
          // WITHDRAW_SUBMIT(1), // 提交中
          // WITHDRAW_CANCEL(2), // 取消
          // WITHDRAW_AUDIT(3), // 审核中
          // WITHDRAW_REFUSE(4), // 拒绝
          // WITHDRAW_ACCEPT(5), // 受理
          // WITHDRAW_PAYFAIL(6), // 打款失败
          // WITHDRAW_SUCCESS(7), // 出金成功
          // WITHDRAW_FAIL(8), // 出金失败
          // WITHDRAW_PENDING(9), // 没有决定的状态，挂着
          // WITHDRAW_HALFFAILED(10); // 部分失败
          11: 'withdraw.status.transferSubmitted',
          12: 'withdraw.status.transferSuccess',
          13: 'withdraw.status.transferRejected',
          14: 'withdraw.status.transferFailed',
          15: 'withdraw.status.transferProcessing'
          //10+status
          // TRANSFER_SUBMITED(1), // 提交
          // TRANSFER_SUCCESS(2), // 转账成功
          // TRANSFER_REJECT(3), // 审核拒绝
          // TRANSFER_FAIL(4), // 转账失败
          // TRANSFER_PROCESSING(5); // mt4入金成功更新数据库失败--处理中
        };
        return statuses[status];
      };
    },
    withdrawTypeDesc() {
      return function (status) {
        const statuses = {
          //country id: 1, Australia; 2, International bank; 3, China
          2: 'common.withdrawChannel.banktransferinternational',
          3: 'common.withdrawChannel.skrill_neteller',
          4: 'common.withdrawChannel.unionpay',
          5: 'common.withdrawChannel.thailandinstantbankwiretransfer',
          6: 'common.withdrawChannel.malaysiainstantbankwiretransfer',
          7: 'common.withdrawChannel.fasapay',
          8: 'common.withdrawChannel.vietnaminstantbankwiretransfer',
          9: 'common.withdrawChannel.nigeriainstantbankwiretransfer',
          10: 'common.withdrawChannel.transfer',
          21: 'common.withdrawChannel.aus',
          22: 'common.withdrawChannel.int',
          23: 'common.withdrawChannel.chn',
          24: 'common.withdrawChannel.indiainstantbanktransfer', //india
          31: 'common.withdrawChannel.skrill',
          32: 'common.withdrawChannel.neteller',
          34: 'common.withdrawChannel.cryptocurrencybitcoin',
          35: 'common.withdrawChannel.southkoreabanktransfer',
          36: 'common.withdrawChannel.cryptocurrencyusdt',
          37: 'common.withdrawChannel.bitwallet',
          38: 'common.withdrawChannel.sticpay',
          39: 'common.withdrawChannel.indonesiainstantbankwiretransfer',
          40: 'common.withdrawChannel.philippinesinstantbankwiretransfer',
          42: 'common.withdrawChannel.southafricainstantbankwiretransfer',
          43: 'common.withdrawChannel.southafricainstantbankwiretransfer',
          50: 'common.withdrawChannel.ugandabanktransfer',
          51: 'common.withdrawChannel.rwandabanktransfer',
          52: 'common.withdrawChannel.zambiabanktransfer',
          53: 'common.withdrawChannel.congobanktransfer',
          54: 'common.withdrawChannel.cameroonbanktransfer',
          55: 'common.withdrawChannel.burundibanktransfer',
          56: 'common.withdrawChannel.kenyabanktransfer',
          57: 'common.withdrawChannel.ghanabanktransfer',
          58: 'common.withdrawChannel.tanzaniabanktransfer',
          60: 'common.withdrawChannel.internetbankingcanada',
          61: 'common.withdrawChannel.perfectmoney',
          999: 'common.withdrawChannel.nigeriabanktransfer'
        };
        if (status === 2 && this.countryCode == this.ngaCountryCode && this.regulator === 'svg') return statuses[999];
        return statuses[status];
      };
    },
    regulator() {
      return this.$store.state.common.regulator;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    }
  },
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/leads.scss';
// @import '@/assets/css/pages/rebatePaymentHistory.scss';
// @import '@/assets/css/components/pagination.scss';
.history_rebate {
  margin-top: 16px;
}
</style>
